module.exports = () => {
  console.log(`
  ===================================================================

                            Site built by

  ┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼
  ┼┼█┼┼┼┼┼┼███┼┼█┼┼┼█┼████┼┼┼███┼┼┼┼┼████┼┼████┼┼┼███┼┼█┼┼┼█┼█┼┼┼█┼┼┼
  ┼┼█┼┼┼┼┼█┼┼┼█┼█┼┼┼█┼█┼┼┼█┼█┼┼┼█┼┼┼┼█┼┼┼█┼█┼┼┼█┼█┼┼┼█┼█┼┼┼█┼██┼┼█┼┼┼
  ┼┼█┼┼┼┼┼█████┼█┼┼┼█┼████┼┼█████┼┼┼┼████┼┼████┼┼█┼┼┼█┼█┼┼┼█┼█┼█┼█┼┼┼
  ┼┼█┼┼┼┼┼█┼┼┼█┼█┼┼┼█┼█┼┼┼█┼█┼┼┼█┼┼┼┼█┼┼┼█┼█┼┼┼█┼█┼┼┼█┼█┼█┼█┼█┼┼██┼┼┼
  ┼┼█████┼█┼┼┼█┼┼███┼┼█┼┼┼█┼█┼┼┼█┼┼┼┼████┼┼█┼┼┼█┼┼███┼┼┼█┼█┼┼█┼┼┼█┼┼┼
  ┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼
  ┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼┼


                          www.laurabrown.xyz

  ===================================================================
  `);
}